<script setup lang="ts"></script>

<template>
  <header class="h-16 items-center px-8">
    <div
      class="max-w-9xl mx-auto h-16 flex items-center justify-between bg-white"
    >
      <div class="left">
        <slot></slot>
      </div>
      <div class="right">
        <slot name="right"></slot>
      </div>
    </div>
  </header>
</template>
