<script setup lang="ts">
import type { RouteLocationRaw } from "vue-router"
import { ChevronRightIcon } from "@heroicons/vue/20/solid"

interface Breadcrumb {
  name: string
  to?: RouteLocationRaw
}

interface Props {
  items: Breadcrumb[]
}

const currentClasses = "text-slate-600"
const parentClasses = "text-slate-400 hover:text-slate-700"

const props = withDefaults(defineProps<Props>(), {
  items: () => [],
})

function classes(item: Breadcrumb): string {
  if (isCurrent(item)) return currentClasses
  return parentClasses
}

function isCurrent(item: Breadcrumb): boolean {
  return item === props.items[props.items.length - 1]
}
</script>

<template>
  <nav class="flex" aria-label="Breadcrumb">
    <ol role="list" class="flex py-1 items-center space-x-2">
      <li v-for="(item, index) in items" :key="item.name">
        <div class="flex items-center">
          <ChevronRightIcon
            v-if="index"
            class="h-5 w-5 flex-shrink-0 text-slate-400"
            aria-hidden="true"
          />
          <NuxtLink
            :to="item.to"
            class="ml-2 text-base font-medium first:ml-0"
            :class="classes(item)"
            :aria-current="isCurrent(item) ? 'page' : undefined"
          >
            {{ item.name }}
          </NuxtLink>
        </div>
      </li>
    </ol>
  </nav>
</template>
